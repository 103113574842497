<template>
  <Layout tituloPagina="Sistema | Cuenta | Pago | Pendiente">
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Pago pendiente
            </h4>
          </div>
          <div class="card-body">
            <p>
              Gracias por su compra. Queremos informarle que su pago está actualmente pendiente. Estamos trabajando para completar esta revisión lo antes posible y procesar su pago.
              Por lo general, este proceso de revisión no toma mucho tiempo. Le notificaremos tan pronto como su pago sea aprobado.
              Agradecemos su paciencia y comprensión.
            </p>
            <p>
              Cualquier duda o aclaración nos reiteramos a tus ordenes
              vía WhatsApp
              <a href="https://wa.me/5215527039900" target="_blank">Soporte para ArgusBlack</a>.
            </p>
            <p>
              Da click en el siguiente botón para regresar al panel de cuenta.
            </p>
          </div>
        </div>
        <div class="text-right">
          <router-link class="btn btn-success" :to="{ name: 'sistemaCuenta' }">
            Cuenta
          </router-link>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"

export default {
  name: 'Pendiente',
  components: { Layout },
  data() {
    return {
    }
  },

  created: function() {
  }
}
</script>

<style>
</style>